
export default {
	namespaced: true,
	state: () => ({
		connected: false,
		address: null,
		wallet: null,
		jwtToken: localStorage.getItem("rugcheck:jwt_token") || null
	}),
	mutations: {
		set_jwt(state, token) {
			state.jwtToken = token;
			localStorage.setItem("rugcheck:jwt_token", token)
		},
		clear_jwt(state) {
			state.jwtToken = null;
			localStorage.removeItem("rugcheck:jwt_token")
		},
		set_wallet(state, wallet) {
			state.wallet = wallet;
		},
		set_wallet_connected(state, connected) {
			state.connected = connected;
		},

		set_wallet_address(state, addr) {
			state.address = addr;
		},

		clear_wallet(state) {
			console.log("Clearing wallet state")
			state.connected = false
			state.address = null
			state.wallet = null
			state.jwtToken = null
			localStorage.removeItem("rugcheck:jwt_token")
			localStorage.removeItem("walletName")
		}
	},
	actions: {
		walletConnected({commit, state}) {
			if (state.connected)
				return

			commit("set_wallet_connected", true)
		}
	}
}
