import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: '',
		component: () => import(/* webpackChunkName: "home" */ '../layouts/DefaultLayout.vue'),
		children: [
			{
				path: '/',
				name: 'Home',
				component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
			},
			{
				path: '/auth',
				name: 'Login',
				component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login.vue')
			},
			{
				path: '/chill',
				name: 'Chill Out',
				component: () => import(/* webpackChunkName: "chill" */ '../views/auth/RateLimited.vue')
			},
			{
				path: '/maintenance',
				name: 'Maintenance',
				component: () => import(/* webpackChunkName: "maint" */ '../views/Maintenance.vue')
			},
			{
				path: '/leaderboard',
				name: 'Leaderboard',
				component: () => import(/* webpackChunkName: "leaderboard" */ '../views/Leaderboard.vue')
			},
			{
				path: '/integrations',
				name: 'Integrations',
				component: () => import(/* webpackChunkName: "integrations" */ '../views/Integrations.vue')
			},
		]
	},
	{
		path: '/docs',
		name: '',
		component: () => import(/* webpackChunkName: "docs" */ '../layouts/DefaultLayout.vue'),
		children: [
			{
				path: '/about',
				name: 'About RugCheck',
				component: () => import(/* webpackChunkName: "about" */ '../views/docs/About.vue')
			},
			{
				path: '/privacy',
				name: 'Privacy Policy',
				component: () => import(/* webpackChunkName: "about" */ '../views/docs/Privacy.vue')
			},
		]
	},
	{
		path: '/app',
		name: '',
		component: () => import(/* webpackChunkName: "auth_app" */ '../layouts/DefaultLayout.vue'),
		children: [
			{
				path: '/settings',
				name: 'Settings',
				component: () => import(/* webpackChunkName: "settings" */ '../views/SettingView.vue')
			},
			{
				path: '/tokens',
				name: 'Tokens',
				component: () => import(/* webpackChunkName: "token" */ '../views/token/TokenIndex.vue')
			},
			{
				path: '/tokens/:id',
				name: 'Token',
				component: () => import(/* webpackChunkName: "token" */ '../views/token/TokenShow.vue')
			},
			{
				path: '/tokens/:id/insiders',
				name: 'Token Insiders',
				component: () => import(/* webpackChunkName: "token" */ '../views/token/TokenInsiders.vue')
			},
			{
				path: '/tokens/:id/insiders/table',
				name: 'Token Insiders',
				component: () => import(/* webpackChunkName: "token" */ '../views/token/TokenInsidersTable.vue')
			},
		]
	},
	{
		path: '/',
		name: '',
		component: () => import(/* webpackChunkName: "auth_app" */ '../layouts/AuthenticatedLayout.vue'),
		children: [
			{
				path: '/verify/token',
				name: 'Token Verification',
				component: () => import(/* webpackChunkName: "token" */ '../views/verification/TokenApplication.vue')
			},
		]
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior() {
		return {top: 0}
	},
	routes
})

export default router
