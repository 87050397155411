
export default {
	namespaced: true,
	state: () => ({
		known: {},
	}),
	mutations: {
		set_known_accounts(state, accounts) {
			state.known = accounts
		},
		add_known_accounts(state, accounts) {
			state.known = Object.assign(state.known, accounts)
		},
	},
	actions: {
		async fetch_known_accounts({ commit }) {
			const resp = await fetch('https://api.rugcheck.xyz/public/known_accounts.json').catch(e => {console.error("failed to fetch known accounts", e)});
			if (!resp)
				throw "failed to fetch known accounts"

			commit('set_known_accounts', await resp.json());
		},
	}
}
