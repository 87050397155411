<template>
	<nav class="navbar navbar-expand-lg navbar-dark bg-dark gradient-primary" :key="$store.state.wallet.address">
		<div class="container-fluid">
			<router-link class="navbar-brand" to="/">
				<img src="/favicon.jpg" height="40" class="d-inline-block align-top me-2" style="border-radius: 4px" alt="">
				<span class="logo-name" style="line-height: 40px">RugCheck</span>
			</router-link>
			<button id="toggle" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse text-center" id="navbarSupportedContent">
				<ul class="navbar-nav me-auto mb-lg-0">
					<li class="nav-item">
						<router-link class="nav-link" to="/">🔍HOME</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" to="/tokens">📊TOKENS</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" to="/verify/token">🛡️VERIFY <sup class="badge bg-primary px-1 py-0 d-lg-none d-xl-inline"><small>New</small></sup></router-link>
					</li>
<!--					<li class="nav-item">-->
<!--						<router-link class="nav-link" to="/leaderboard">LEADERBOARD</router-link>-->
<!--					</li>-->
				</ul>

				<ul class="navbar-nav mx-auto mb-lg-0">
					<li class="nav-item">
						<router-link class="nav-link" to="/integrations">🧰INTEGRATIONS <sup class="badge bg-primary px-1 py-0 d-lg-none d-xl-inline"><small>New</small></sup></router-link>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="https://fluxbeam.xyz" target="_blank">💱DEX</a>
					</li>
				</ul>

				<ul class="navbar-nav ms-auto mb-lg-0">
					<li class="nav-item">
						<router-link class="nav-link" to="/settings"><i class="fa fa-cog"></i></router-link>
					</li>
					<li class="nav-item" v-if="$route.path.indexOf('auth') === -1">
						<MultiWallet @disconnect="logout" class="login mt-1  wow fadeIn"></MultiWallet>
					</li>
				</ul>
			</div>
		</div>
	</nav>
</template>

<script>
import MultiWallet from "@/components/wallet/MultiWallet.vue";

export default {
	name: "Navbar",
	components: {MultiWallet},
	methods: {
		logout: function (e) {
			if (e)
				e.preventDefault()

			console.log("Logging out")
			this.$toastr.e("Wallet disconnected")
			this.$store.commit("wallet/clear_wallet")
			localStorage.removeItem("wallet")
			localStorage.removeItem("walletName")
		},
	}
}
</script>