import {web3} from "@project-serum/anchor";

export default {
	namespaced: true,
	state: () => ({
		endpoint: "",
		commitment: "confirmed",
		client: null,
	}),
	mutations: {
		set_endpoint(state, url) {
			state.endpoint = url
			state.client = new web3.Connection(state.endpoint, state.commitment)
		},
		set_commitment(state, commitment) {
			state.commitment = commitment
			state.client = new web3.Connection(state.endpoint, state.commitment)
		},
	}
}
