export default {
	namespaced: true,
	state: () => ({
		tokenPrices: {},
		tokenMap: {},
	}),
	mutations: {
		set_token_price(state, tokenPrice = {mint: "", price: 0.0}) {
			tokenPrice.updatedAt = Date.now()
			state.tokenPrices[tokenPrice.mint] = tokenPrice
		},
		set_token_map(state, m) {
			state.tokenMap = Object.fromEntries(m.map((x) => [x.address, x]))
		},
		add_token_map(state, {k,v}) {
			state.tokenMap[k] = v
		},
	}
}
