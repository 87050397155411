import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'

// @ts-ignore
import VueToastr from "vue-toastr";
import PortalVue from 'portal-vue'

import 'animate.css';

import "@fortawesome/fontawesome-free/js/all";
import "@/assets/css/wallet_adapter.css";
import "@/assets/css/wallet_adapter_custom.css";
import "@/assets/css/theme.css";

Vue.config.productionTip = false;


Vue.use(PortalVue)

Vue.use(VueToastr, {
	defaultProgressBar: true,
	defaultPosition: "toast-bottom-left"
});

// eslint-disable-next-line no-unused-vars
// router.afterEach((to, from) => {
// 	Vue.nextTick(() => {
// 		let title = 'RugCheck'
// 		if (to.name)
// 			title += ` | ${to.name}`
//
// 		document.title = title;
// 	});
// });

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')