export default {
	namespaced: true,
	state: () => (localStorage.getItem("rugcheck:settings") ? JSON.parse(localStorage.getItem("rugcheck:settings")) : {
		explorer: "https://solana.fm",
		charting: "https://birdeye.so/token",
		rpcUrl: "https://swr.xnftdata.com/rpc-proxy/",
		customRpcUrl: "",
		slippage: 5,
		degenModeLevel: "shrimp"
	}),
	mutations: {
		update(state, settings) {
			const ok = Object.keys(settings)
			console.log("ok",{ok,settings})
			for (let i = 0; i < ok.length; i++)
				state[ok[i]] = settings[ok[i]]

			localStorage.setItem("rugcheck:settings", JSON.stringify(state))
		},
	}
}
