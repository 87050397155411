<template>
	<WalletMultiButton :openOnboardingUrls="false" @copy="onCopy"
			@error="onWalletError" @connect="onConnected"
			@disconnect="onDisconnect"
			:wallets="[]"
			dark></WalletMultiButton>
</template>

<script>
import {WalletMultiButton} from "@alphabatem/vue2-wallet-adapter";

export default {
	name: "MultiWallet",
	components: {
		WalletMultiButton
	},
	props: {
		button_text: {
			type: String,
			default: function () {
				return "Connect Wallet"
			}
		}
	},
	data() {
		return {
			key: 0,
			connectedNotif: false,
		}
	},
	methods: {
		onDisconnect() {
			console.log("MultiWallet::Disconnect")
			this.$toastr.e("Wallet disconnected")

			this.$store.commit("wallet/clear_wallet")
			this.$store.commit("wallet/clear_jwt")
			this.$emit("disconnect")
		},

		onCopy(addr) {
			this.$toastr.s(addr, "Address copied to clipboard")
		},

		onWalletError(e) {
			this.$toastr.e(e, "Wallet Error")
		},

		onConnected(e) {
			this.$store.commit('wallet/set_wallet', e.adapter);
			this.$store.commit('wallet/set_wallet_address', e.adapter?.publicKey?.toString());
			this.$store.dispatch("wallet/walletConnected");
			this.$emit("connected", true)
			this.key++
			window.gtag("event", "wallet_connect")

			if (!this.connectedNotif) {
				this.$toastr.s(e.adapter?.publicKey?.toString(), "Wallet connected!")
				this.connectedNotif = true
			}
		},
	},
}
</script>

<style scoped>
</style>
