<template>
	<div class="footer d-flex px-3 py-1">
		<div class="col-auto">
			RugCheck
		</div>
		<div class="col text-center">
			<router-link to="/about">ABOUT</router-link>
			<span class="mx-2">|</span>
			<router-link to="/tokens">TOKENS</router-link>
			<span class="mx-2">|</span>
			<a href="https://fluxbeam.xyz/app/tools" target="_blank">LAUNCH TOOLS</a>
			<span class="mx-2">|</span>
			<a href="https://api.rugcheck.xyz/swagger/index.html" target="_blank">API</a>
			<span class="mx-2">|</span>
			<router-link to="/integrations">INTEGRATIONS</router-link>
			<!--		<router-link class="mx-2" to="/about">DOCS</router-link>-->
			<!--		<router-link class="mx-2" to="/about">DEVELOPERS</router-link>-->
		</div>
		<div class="col-auto text-end">
			<a href="https://x.com/Rugcheckxyz" target="_blank"><img alt="" style="height: 0.75rem" :src="`/images/socials/twitter.svg`" title="@Rugcheckxyz on X"></a>
		</div>
	</div>
</template>

<script>
export default {
	name: "Footer"
}
</script>

<style scoped>
.footer {
	left: 0;
	bottom: 0;
	width: 100%;

	font-size: 0.75rem;
	background: #111;
	color: white;
	fill: white;
}

img {
	-webkit-filter: invert(1);
	filter: invert(1);
}
</style>